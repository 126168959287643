		
<template>
<div class="outer-wrapper">
    <div class="auth update-password">

		<div class="inner-wrapper">
		
			<div class="heading-section">
				<h1 class="heading3">Choose a new password</h1>
			</div>

			<form @submit.prevent="handleSubmit">
				<div class="form-group">
					<label htmlFor="password">New Password</label>
					<input type="password" v-model="newUser.password" name="password" class="form-control" :class="{ 'is-invalid': submitted && errors.has('password') }" />
					<div v-if="submitted && errors.has('password')" class="invalid-feedback">{{ errors.get('password')[0] }}</div>
				</div>
				<div class="form-group">
					<label htmlFor="password">Confirm Password</label>
					<input type="password" v-model="newUser.passwordReenter" name="passwordReenter" class="form-control" :class="{ 'is-invalid': submitted && errors.has('passwordReenter') }" />
					<div v-if="submitted && errors.has('passwordReenter')" class="invalid-feedback">{{ errors.get('passwordReenter')[0] }}</div>
				</div>
				<div v-if="alert.message" :class="`alert ${alert.type}`">{{alert.message}}</div>
				<div class="form-group btn-container">
					<button class="button btn-primary" >Continue</button>
				</div>
			</form>
 	  </div>
    </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import router from '@/router';
import account from '@/helpers/account.js';

export default {
    data () {
        return {
			submitted: true,
			errors: new Map(),

			uniqueCode: this.$route.params.code ? this.$route.params.code : false,
        }
    },
    computed: {
		...mapState('account', ['status', 'newUser']),
		...mapState({
			alert: state => state.alert,
		}),
    },
    methods: {
        ...mapActions('account', ['updatePassword']),
		...mapActions({
			alertError: "alert/error",
			alertSuccess: "alert/success",
		}),
        async handleSubmit(e) {
            if(this.checkForm()) {
				var success = await this.updatePassword({ password: this.newUser.password, passwordReenter: this.newUser.passwordReenter, uniqueCode: this.uniqueCode });
				if(success) {
					this.alertSuccess("Password successfully updated");
					router.push({ name: "Update Password Success" }); 
				} else {
					this.alertError("Something went wrong");
					router.push({ name: "Update Password Fail" });
				}
			}
		},
		checkForm() {
			this.errors = new Map();

			let { passwordErrors, passwordReenterErrors } = account.validatePassword(this.newUser.password, this.newUser.passwordReenter)
			
			if(passwordErrors.length > 0) {
				this.errors.set('password', passwordErrors);
			}
			if(passwordReenterErrors.length > 0) {
				this.errors.set('passwordReenter', passwordReenterErrors);
			}

			if (!this.errors.size > 0) {
				return true;
			}
			return false;
			e.preventDefault();
		},
	},
	created() {
		this.$emit('update:topLayoutSettings', {
			left: {
				type: 'none',
			},
			right: {
				type: 'exit',
			},
		});
	}
};
</script>	


		